import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BarcodeScanDialogComponent } from '../dialogs';

@Injectable({
    providedIn: 'root',
})
export class BarcodeScanDialogService {
    readonly #dialog = inject(MatDialog);

    public openBarcodeScanDialog(): MatDialogRef<BarcodeScanDialogComponent> {
        return this.#dialog.open<BarcodeScanDialogComponent>(BarcodeScanDialogComponent, {
            disableClose: true,
        });
    }
}
