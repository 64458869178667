import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
    GeneralOperationSetting,
    GeneralOperationSettings,
    OperationSettingsEntry,
} from '@data-terminal/shared-models';

import { SettingsOperationsApiService } from '../settings-operations-api/settings-operations-api.service';
import { omitEntityValues } from '@data-terminal/utils';
import { OrganizationInfo } from '@data-terminal/data-access';

@Injectable({
    providedIn: 'root',
})
export class SettingsOperationsService {
    readonly #settingsOperationsApiService = inject(SettingsOperationsApiService);

    public getOperationSettings(): Observable<OperationSettingsEntry[]> {
        return this.#settingsOperationsApiService.getOperationSettings().pipe(map((response) => response.data ?? []));
    }

    public setOperationSettings(settings: OperationSettingsEntry): Observable<OperationSettingsEntry[]> {
        return this.#settingsOperationsApiService.setOperationSettings(omitEntityValues(settings));
    }

    public prepareSettingsToDisplay(
        organizationInfo: OrganizationInfo,
        operationSettingsEntry: OperationSettingsEntry,
        isDataCaptureUser: boolean
    ): GeneralOperationSetting[] {
        const { automaticallyLoadAllByDeviceClass, automaticallyCreateUnplanned, materialConsumption, batchReport } =
            operationSettingsEntry;

        let settingsObj: { [setting: string]: boolean } = {
            automaticallyLoadAllByDeviceClass,
            automaticallyCreateUnplanned,
        };

        if (organizationInfo.consumptionReport) {
            settingsObj = { ...settingsObj, materialConsumption, batchReport };
        }

        return Object.entries(settingsObj).map(([key, value]) => {
            return {
                settingName: key,
                settingValue: value,
                disabled:
                    !isDataCaptureUser ||
                    this.#calculateDisableState(operationSettingsEntry, key as GeneralOperationSettings),
                translationKey: this.#calculateTranslationKey(key as GeneralOperationSettings),
                helpTextTranslationKey: this.#calculateHelpTextKey(key as GeneralOperationSettings),
                isSubmenuOption: this.#isSubmenuOption(key as GeneralOperationSettings),
            };
        });
    }

    public calculateUpdatedEntry(
        operationSettingsEntry: OperationSettingsEntry,
        changedSetting: Pick<GeneralOperationSetting, 'settingName' | 'settingValue'>
    ): OperationSettingsEntry {
        let updatedEntry: OperationSettingsEntry = {
            ...operationSettingsEntry,
            automaticallyLoadAllByDeviceClass:
                changedSetting.settingName === GeneralOperationSettings.automaticallyLoadAllByDeviceClass
                    ? changedSetting.settingValue
                    : operationSettingsEntry.automaticallyLoadAllByDeviceClass,
            automaticallyCreateUnplanned:
                changedSetting.settingName === GeneralOperationSettings.automaticallyCreateUnplanned
                    ? changedSetting.settingValue
                    : operationSettingsEntry.automaticallyCreateUnplanned,
            materialConsumption:
                changedSetting.settingName === GeneralOperationSettings.materialConsumption
                    ? changedSetting.settingValue
                    : operationSettingsEntry.materialConsumption,
            batchReport:
                changedSetting.settingName === GeneralOperationSettings.batchReport
                    ? changedSetting.settingValue
                    : operationSettingsEntry.batchReport,
        };

        if (
            !changedSetting.settingValue &&
            changedSetting.settingName === GeneralOperationSettings.automaticallyLoadAllByDeviceClass
        ) {
            updatedEntry = { ...updatedEntry, automaticallyCreateUnplanned: false };
        }

        if (
            !changedSetting.settingValue &&
            changedSetting.settingName === GeneralOperationSettings.materialConsumption
        ) {
            updatedEntry = { ...updatedEntry, batchReport: false };
        }

        return updatedEntry;
    }

    #calculateDisableState(operationSettingsEntry: OperationSettingsEntry, key: GeneralOperationSettings): boolean {
        if (
            key === GeneralOperationSettings.automaticallyLoadAllByDeviceClass ||
            key === GeneralOperationSettings.materialConsumption
        ) {
            return false;
        } else if (key === GeneralOperationSettings.automaticallyCreateUnplanned) {
            return !operationSettingsEntry.automaticallyLoadAllByDeviceClass;
        } else if (key === GeneralOperationSettings.batchReport) {
            return !operationSettingsEntry.materialConsumption;
        }
        return true;
    }

    #calculateTranslationKey(key: GeneralOperationSettings): string {
        if (key === GeneralOperationSettings.automaticallyLoadAllByDeviceClass) {
            return 'DC.OPERATIONS_SETTINGS.LOAD_ALL_OPERATIONS';
        }
        if (key === GeneralOperationSettings.automaticallyCreateUnplanned) {
            return 'DC.OPERATIONS_SETTINGS.AUTOMATICALLY_CREATE_UNPLANNED_WORK';
        }
        if (key === GeneralOperationSettings.materialConsumption) {
            return 'DC.OPERATIONS_SETTINGS.MATERIAL_CONSUMPTION';
        }
        if (key === GeneralOperationSettings.batchReport) {
            return 'DC.OPERATIONS_SETTINGS.BATCH_NOTIFICATION';
        }
        return '';
    }

    #calculateHelpTextKey(key: GeneralOperationSettings): string | undefined {
        if (key === GeneralOperationSettings.automaticallyCreateUnplanned) {
            return 'DC.OPERATIONS_SETTINGS.AUTOMATICALLY_CREATE_UNPLANNED_WORK.HELP';
        }

        if (key === GeneralOperationSettings.materialConsumption) {
            return 'DC.OPERATIONS_SETTINGS.MATERIAL_CONSUMPTION.HELP';
        }

        return undefined;
    }

    #isSubmenuOption(key: GeneralOperationSettings): boolean {
        return (
            key === GeneralOperationSettings.automaticallyCreateUnplanned ||
            key === GeneralOperationSettings.batchReport
        );
    }
}
