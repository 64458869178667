import { inject, Injectable } from '@angular/core';
import { filter, map, Observable, take } from 'rxjs';

import { BarcodeScanDialogService } from '@data-terminal/ui-smart';
import { SearchInputComponent } from '@data-terminal/ui-presentational';

@Injectable({
    providedIn: 'root',
})
export class BarcodeSearchDialogService {
    readonly #barcodeScanDialogService = inject(BarcodeScanDialogService);

    public openBarcodeDialogForSearch(searchInputComponent: SearchInputComponent): {
        afterClosed: () => Observable<string>;
    } {
        const barcodeScanDialog = this.#barcodeScanDialogService.openBarcodeScanDialog();

        barcodeScanDialog.componentInstance.manualSearchClick.pipe(take(1)).subscribe(() => {
            searchInputComponent.focus();
        });

        return {
            afterClosed: () =>
                barcodeScanDialog.afterClosed().pipe(
                    take(1),
                    filter((scannedValue) => !!scannedValue),
                    map((scannedValue) => this.extractDataFromScannedValue(scannedValue))
                ),
        };
    }

    private extractDataFromScannedValue(scannedValue: string): string {
        const SCAN_DELIMITER = '$';
        const splitValues = scannedValue.split(SCAN_DELIMITER);

        if (splitValues.length === 1) {
            return splitValues[0];
        } else {
            return splitValues.slice(1, splitValues.length).join(SCAN_DELIMITER);
        }
    }
}
