import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BACKEND_URL, DataTerminalSessionService, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { DataTransferSettings } from 'projects/shared-models/src/lib/datatransfer';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SettingsDataTransferApiService {
    private readonly DATATRANSFER_URL = `${this.backendUrl}${this.orgRequestPrefix}settings`;

    constructor(
        private readonly http: HttpClient,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getDataTransferSettings(): Observable<DataTransferSettings> {
        return this.http.get<DataTransferSettings>(this.DATATRANSFER_URL);
    }

    public setDataTransferSettings(body: DataTransferSettings): Observable<DataTransferSettings> {
        return this.http.post<DataTransferSettings>(this.DATATRANSFER_URL, body);
    }
}
