import { NgModule, Pipe, PipeTransform } from '@angular/core';

import { ActivityState, Operation, OperationNeedsAvailable } from '@data-terminal/shared-models';

@Pipe({
    name: 'operationState',
})
export class OperationStatePipe implements PipeTransform {
    public transform(operation: Operation): string {
        if (operation.opState === ActivityState.WAITING) {
            return this.handleWaitingState(operation);
        }
        return 'DC.OPERATION_CARD.STATES.' + operation.opState.toUpperCase();
    }

    private handleWaitingState(operation: Operation): string {
        if (
            operation.opNeedsAvailable === OperationNeedsAvailable.GREEN &&
            operation.opPlannedStart < new Date().getTime() &&
            operation.opPlannedStart !== 0
        ) {
            return 'DC.OPERATION_CARD.STATES.WAITING.START_TIME_EXCEEDED';
        }
        if (operation.opNeedsAvailable === OperationNeedsAvailable.RED) {
            return 'DC.OPERATION_CARD.STATES.WAITING.WAITING_ERROR';
        }
        if (operation.opNeedsAvailable === OperationNeedsAvailable.YELLOW) {
            return 'DC.OPERATION_CARD.STATES.WAITING.WAITING_WARNING';
        }
        return 'DC.OPERATION_CARD.STATES.WAITING.CAN_BE_STARTED';
    }
}

@NgModule({
    declarations: [OperationStatePipe],
    exports: [OperationStatePipe],
})
export class OperationStateModule {}
