<section class="hdmui hdmui-background-1 settings-workstations-operation-card">
    @if (elements$ | async; as elements) {
        <div class="hdmui-m-0">
            <mat-toolbar>
                <h2>{{ 'DC.MACHINE_CLASS.' + machineClass.toUpperCase() | translate }}</h2>
                <span class="spacer"></span>
                <hdmui-appearance-customizer
                    [title]="'DC.SETTINGS.OPERATIONS.DIALOG.TITLE' | translate"
                    [info]="'*.appearanceCustomizer.info' | translate"
                    [confirm]="'*.apply' | translate"
                    [disable]="disabled"
                    [entry]="entry"
                    [counter]="counter"
                    [reset]="reset"
                    [validation]="validation"
                    [elements]="elements"
                    (elementsChange)="onElementsChangeEvent($event, machineClass)"
                ></hdmui-appearance-customizer>
            </mat-toolbar>
            @if (elementNames$ | async; as elementNames) {
                <div class="hdmui-m-4 settings-workstations-operation-card__content">
                    <data-terminal-operation-card
                        [attrOrder]="elementNames"
                        [operation]="operation"
                    ></data-terminal-operation-card>
                </div>
            } @else {
                <data-terminal-loading-indicator></data-terminal-loading-indicator>
            }
        </div>
    } @else {
        <data-terminal-loading-indicator></data-terminal-loading-indicator>
    }
</section>
