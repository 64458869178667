import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BACKEND_URL, DataTerminalSessionService, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { OperationCardEntity } from '@data-terminal/shared-models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OperationCardAttributeOrderApiService {
    private readonly OPERATIONCARDATTRIBUTESLIST_URL = `${this.backendUrl}${this.orgRequestPrefix}operationcardsettingslist`;
    private readonly OPERATIONCARDATTRIBUTES_URL = `${this.backendUrl}${this.orgRequestPrefix}operationcardsettings`;

    constructor(
        private readonly http: HttpClient,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public getOperationCardAttributeOrder(): Observable<OperationCardEntity[]> {
        return this.http.get<OperationCardEntity[]>(this.OPERATIONCARDATTRIBUTESLIST_URL);
    }

    public postOperationCardAttributeOrder(body: OperationCardEntity): Observable<OperationCardEntity> {
        return this.http.post<OperationCardEntity>(this.OPERATIONCARDATTRIBUTES_URL, body);
    }
}
