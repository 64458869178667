import { Component } from '@angular/core';
import { ROUTE_PATH } from '@data-terminal/shared-models';
import { AngularSplitModule } from 'angular-split';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { LegalLineComponent } from '../../../../../../src/app/components/legal-line/legal-line.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';

@Component({
    selector: 'data-terminal-settings-wrapper',
    templateUrl: './settings-wrapper.component.html',
    styleUrls: ['./settings-wrapper.component.scss'],
    standalone: true,
    imports: [
        AngularSplitModule,
        RouterOutlet,
        LegalLineComponent,
        TranslateModule,
        MatSelectionList,
        MatListOption,
        RouterLink,
        RouterLinkActive,
    ],
})
export class SettingsWrapperComponent {
    public readonly links = [
        { path: `./${ROUTE_PATH.settingsConfirmation}`, title: 'DC.SETTINGS.NAV.NOTIFICATIONS' },
        { path: `./${ROUTE_PATH.settingsDataTransfer}`, title: 'DC.SETTINGS.NAV.DATATRANSFER' },
        { path: `./${ROUTE_PATH.operations}`, title: 'DC.SETTINGS.NAV.OPERATIONS' },
    ];
}
