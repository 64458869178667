import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Operation, OperationNeedsAvailable, ActivityState } from '@data-terminal/shared-models';

@Pipe({
    name: 'statusIcon',
})
export class StatusIconPipe implements PipeTransform {
    private readonly DEFAULT = { icon: '', iconClass: '' } as const;

    private readonly iconOverrides: Map<ActivityState, { icon: string; iconClass: string }> = new Map<
        ActivityState,
        { icon: string; iconClass: string }
    >([
        [ActivityState.WAITING, { icon: 'hdmui:clock', iconClass: 'hdmui-warning' }],
        [ActivityState.SETUP, { icon: 'hdmui:play', iconClass: '' }],
        [ActivityState.IN_PROGRESS, { icon: 'hdmui:play', iconClass: '' }],
        [ActivityState.PAUSED, { icon: 'hdmui:pauseCircled', iconClass: '' }],
        [ActivityState.SUSPENDED, { icon: 'hdmui:pauseCircled', iconClass: '' }],
        [ActivityState.COMPLETED, { icon: 'hdmui:hookCircled', iconClass: 'hdmui-success' }],
    ]);

    public transform(operation: Operation): { icon: string; iconClass: string } {
        if (operation.opState === ActivityState.WAITING) {
            return operation.opNeedsAvailable === OperationNeedsAvailable.GREEN &&
                (new Date() <= new Date(operation.opPlannedStart) || !operation.opPlannedStart)
                ? {
                      icon: 'hdmui:clock',
                      iconClass: 'hdmui-success',
                  }
                : {
                      icon: 'hdmui:warning',
                      iconClass: 'hdmui-warning',
                  };
        } else {
            return this.iconOverrides.get(operation.opState as ActivityState) || this.DEFAULT;
        }
    }
}

@NgModule({
    declarations: [StatusIconPipe],
    exports: [StatusIconPipe],
})
export class StatusIconModule {}
