import { Component } from '@angular/core';

import { SettingsWrapperComponent } from './pages/settings-wrapper/settings-wrapper.component';

@Component({
    selector: 'data-terminal-feature-settings',
    templateUrl: './feature-settings.component.html',
    standalone: true,
    imports: [SettingsWrapperComponent],
})
export class FeatureSettingsComponent {}
