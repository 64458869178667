import { Injectable } from '@angular/core';
import { SettingsDataTransferApiService } from '../settings-data-transfer-api/settings-data-transfer-api.service';
import { Observable, switchMap, tap } from 'rxjs';
import { DataTransferSettings } from 'projects/shared-models/src/lib/datatransfer';

@Injectable({
    providedIn: 'root',
})
export class SettingsDataTransferService {
    private static readonly defaultDataTransferSettings: DataTransferSettings = {
        logs: false,
        orderbag: false,
        previews: false,
        customerName: false,
        cloudAdminName: '',
    };

    private dataTransferData: DataTransferSettings | undefined = undefined;

    constructor(private readonly settingsDataTransferApi: SettingsDataTransferApiService) {}

    public fetchDataTransferSettings(): Observable<DataTransferSettings> {
        return this.settingsDataTransferApi.getDataTransferSettings().pipe(
            tap((val) => {
                if (val !== null && val !== undefined) {
                    this.dataTransferData = val;
                } else {
                    this.dataTransferData = SettingsDataTransferService.defaultDataTransferSettings;
                }
            })
        );
    }

    public getDataTransferSettings(): DataTransferSettings | undefined {
        if (this.dataTransferData !== null && this.dataTransferData !== undefined) {
            return JSON.parse(JSON.stringify(this.dataTransferData));
        }
        return undefined;
    }

    public static getDefaultDataTransferSettings(): DataTransferSettings {
        return SettingsDataTransferService.defaultDataTransferSettings;
    }

    public setDataTransferSettings(body: DataTransferSettings): void {
        this.settingsDataTransferApi
            .setDataTransferSettings(body)
            .pipe(switchMap(() => this.fetchDataTransferSettings()))
            .subscribe();
    }
}
