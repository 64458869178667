<as-split direction="horizontal" class="settings-wrapper">
    <as-split-area [order]="1" [size]="30">
        <div class="hdmui-m-4">
            <mat-selection-list [disableRipple]="true" [multiple]="false" [hideSingleSelectionIndicator]="true">
                @for (link of links; track link) {
                    <mat-list-option
                        class="settings-wrapper__nav-list"
                        [routerLink]="link.path"
                        routerLinkActive
                        [selected]="rla.isActive"
                        #rla="routerLinkActive"
                        [disableRipple]="true"
                    >
                        {{ link.title | translate }}
                    </mat-list-option>
                }
            </mat-selection-list>
        </div>
    </as-split-area>
    <as-split-area [order]="2" [size]="70">
        <router-outlet></router-outlet>
        <app-legal-line></app-legal-line>
    </as-split-area>
</as-split>
