import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BACKEND_URL, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';
import { mapToRequestMetadataWithRetry, RequestMetadata, RETRY_TIME_DELAY_MS } from '@data-terminal/utils';
import { OperationSettingsEntry, OperationSettingsEntryBody } from '@data-terminal/shared-models';

@Injectable({
    providedIn: 'root',
})
export class SettingsOperationsApiService {
    readonly #httpClient = inject(HttpClient);
    readonly #url = `${inject(BACKEND_URL)}${inject(ORGANIZATION_REQUEST_PREFIX)}operationsettings`;

    private readonly OPERATION_SETTINGS_LIST = `${this.#url}/getOperationSettings`;
    private readonly SET_OPERATION_SETTINGS = `${this.#url}/setOperationSettings`;

    public getOperationSettings(): Observable<RequestMetadata<OperationSettingsEntry[]>> {
        return this.#httpClient.get<OperationSettingsEntry[]>(this.OPERATION_SETTINGS_LIST).pipe(
            mapToRequestMetadataWithRetry({
                baseDelayMs: RETRY_TIME_DELAY_MS,
            })
        );
    }

    public setOperationSettings(settings: OperationSettingsEntryBody): Observable<OperationSettingsEntry[]> {
        return this.#httpClient.post<OperationSettingsEntry[]>(this.SET_OPERATION_SETTINGS, settings);
    }
}
