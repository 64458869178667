<mat-card
    appearance="outlined"
    class="hdmui operation-card"
    [ngClass]="{ selected: isSelected, 'hdmui-background-2': !isSelected }"
    (click)="onCardSelect()"
>
    <div class="hdmui-content hdmui-p-0">
        @if (operation | statusIcon; as icon) {
            <mat-icon class="operation-card__status-icon" [class]="icon.iconClass" [svgIcon]="icon.icon"></mat-icon>
        }
        <h2 class="hdmui-font-serif operation-card__title-container hdmui-mt-2" [matTooltip]="operation.jobNumber">
            {{ operation.jobNumber }}
        </h2>
        <div class="operation-card__grid">
            @for (order of attrOrder; track order) {
                <ng-container
                    *ngTemplateOutlet="
                        {
                            jobName: jobName,
                            opName: opName,
                            opState: opState,
                            opPlannedStart: opPlannedStart,
                            opPlannedGoodCtr: opPlannedGoodCtr,
                            versions: versions,
                            plannedMachine: plannedMachine,
                            dueDate: dueDate,
                            customer: customer,
                            sheet: sheet,
                            article: article,
                            specialOperationInfo: specialOperationInfo,
                        }[order]!
                    "
                ></ng-container>
            }
        </div>

        <ng-template #jobName>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.JOBNAME' | translate"
                [matTooltip]="operation.jobName || ''"
            >
                {{ operation.jobName || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #opName>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.OPERATION' | translate"
                [matTooltip]="operation.opName || ''"
            >
                {{ operation.opName || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #opState>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.STATE' | translate"
                [matTooltip]="(operation | operationState | translate) || ''"
            >
                {{ operation | operationState | translate }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #opPlannedStart>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [ngClass]="{ exceeded: isTimeExceeded() }"
                [label]="'DC.OPERATION_CARD.STARTTIME' | translate"
                [matTooltip]="operation.opPlannedStart ? (operation.opPlannedStart | date: 'short') || '' : ''"
            >
                {{ operation.opPlannedStart ? (operation.opPlannedStart | dateFormatterWithoutYear) : '' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #opPlannedGoodCtr>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [ngClass]="{ exceeded: operation.opPlannedGoodCtr > Number(operation.pred_good) }"
                [label]="'DC.OPERATION_CARD.PLANNEDGOOD' | translate"
                [matTooltip]="operation.opPlannedGoodCtr ? (operation.opPlannedGoodCtr | number) || '' : ''"
            >
                {{ operation.opPlannedGoodCtr ? (operation.opPlannedGoodCtr | number) : '-' }}
                @if (operation.opPlannedGoodCtr !== Number(operation.pred_good)) {
                    {{ Number(operation.pred_good) ? '(' + (operation.pred_good | number) + ')' : '(-)' }}
                }
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #versions>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.VERSIONS' | translate"
                [matTooltip]="operation.versions && operation.versions !== '-' ? operation.versions : ''"
            >
                {{ operation.versions || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #plannedMachine>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.PLANNED_MACHINE' | translate"
                [matTooltip]="operation.plannedMachine ? operation.plannedMachine || '' : ''"
            >
                {{ operation.plannedMachine ? operation.plannedMachine : '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #dueDate>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.DUE_DATE' | translate"
                [matTooltip]="operation.dueDate ? (operation.dueDate | date: 'short') || '' : ''"
            >
                {{ operation.dueDate ? (operation.dueDate | dateFormatterWithoutYear) : '' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #customer>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.CUSTOMER' | translate"
                [matTooltip]="operation.customerName || ''"
            >
                {{ operation.customerName || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #article>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.ARTICLE' | translate"
                [matTooltip]="operation.article || ''"
            >
                {{ operation.article || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #sheet>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.SHEET' | translate"
                [matTooltip]="operation.sheetId || ''"
            >
                {{ operation.sheetId || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>

        <ng-template #specialOperationInfo>
            <hdmui-key-value-vertical
                class="hdmui-mt-0 hdmui-mb-1"
                [label]="'DC.OPERATION_CARD.SPECIAL_OPERATION_INFO' | translate"
                [matTooltip]="operation.specialOperationInfo || ''"
            >
                {{ operation.specialOperationInfo || '-' }}
            </hdmui-key-value-vertical>
        </ng-template>
    </div>
    <mat-progress-bar
        mode="determinate"
        class="operation-card__progress-bar"
        [ngClass]="{
            'operation-card__progress-bar__completed': isCompleted(),
        }"
        [value]="(operation.opCtrGood * 100) / operation.opPlannedGoodCtr"
        style="height: 4px"
    ></mat-progress-bar>
</mat-card>
