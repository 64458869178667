import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BACKEND_URL, DataTerminalSessionService, ORGANIZATION_REQUEST_PREFIX } from '@data-terminal/data-access';

import { ConfirmationSettings } from 'projects/shared-models/src/lib/confirmation';

@Injectable({
    providedIn: 'root',
})
export class SettingsConfirmationApiService {
    private readonly CONFIRMATION_SETTINGS_URL: (userId: string) => string = (userId) =>
        `${this.backendUrl}${this.orgRequestPrefix}confirmation/${userId}`;

    constructor(
        private readonly http: HttpClient,
        private readonly dataTerminalSessionService: DataTerminalSessionService,
        @Inject(BACKEND_URL) private readonly backendUrl: string,
        @Inject(ORGANIZATION_REQUEST_PREFIX) private readonly orgRequestPrefix: string
    ) {}

    public fetchConfirmationSettings(userId: string): Observable<ConfirmationSettings | undefined> {
        return this.http.get<ConfirmationSettings | undefined>(this.CONFIRMATION_SETTINGS_URL(userId));
    }

    public saveConfirmationSettings(body: ConfirmationSettings): Observable<ConfirmationSettings | undefined> {
        return this.http.post<ConfirmationSettings | undefined>(
            this.CONFIRMATION_SETTINGS_URL(this.dataTerminalSessionService.getCurrentDataTerminalUser().userId),
            body
        );
    }
}
